import React from "react";
import {Col, Row} from 'reactstrap';	
import AnimatedNumber from "animated-number-react";

class TotaalOmzet extends React.Component {	
	render() {
		return (
		<Row className="verkoopomzet">
			<Col lg="3">
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts graph-display">
					<h2 className="font-light text-muted" style={{fontSize: 14, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Omzet totaal</h2>	
					<h2 className="font-light text-muted" style={{marginTop: 30, marginBottom: 35, fontSize: 23, fontWeight: 900, color: '#212529',}}>€ <AnimatedNumber duration={500} value={this.state.totaalStats.totaal} formatValue={this.formatCurrency}/></h2>	
					<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
						<h2 className="font-light text-muted" style={{fontSize: 12, marginBottom: 0, color: 'rgba(0,0,0,0.4)',}}>Excl. Servicekosten</h2>	
					</div>
				</div>
				:
				<div className="campaign ct-charts graph-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
			</Col>
			<Col lg="3">
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts graph-display">
					<h2 className="font-light text-muted" style={{fontSize: 14, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Omzet muntjes</h2>
					<h2 className="font-light text-muted" style={{marginTop: 30, marginBottom: 35, fontSize: 23, fontWeight: 900, color: '#212529',}}>€ <AnimatedNumber duration={500} value={this.state.totaalStats.tokens} formatValue={this.formatCurrency}/></h2>
					<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
						<h2 className="font-light text-muted" style={{fontSize: 12, marginBottom: 0, color: 'rgba(0,0,0,0.4)',}}>Excl. Servicekosten</h2>	
					</div>
				</div>
				:
				<div className="campaign ct-charts graph-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
			</Col>
			<Col lg="3">
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts graph-display">
					<h2 className="font-light text-muted" style={{fontSize: 14, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Omzet kluisjes</h2>	
					<h2 className="font-light text-muted" style={{marginTop: 30, marginBottom: 35, fontSize: 23, fontWeight: 900, color: '#212529',}}>€ <AnimatedNumber duration={500} value={this.state.totaalStats.lockers} formatValue={this.formatCurrency}/></h2>	
					<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
						<h2 className="font-light text-muted" style={{fontSize: 12, marginBottom: 0, color: 'rgba(0,0,0,0.4)',}}>Excl. Servicekosten</h2>	
					</div>								
				</div>
				:
				<div className="campaign ct-charts graph-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
			</Col>	
			<Col lg="3">
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts graph-display">
					<h2 className="font-light text-muted" style={{fontSize: 14, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Terugbetalingen</h2>
					<h2 className="font-light text-muted" style={{marginTop: 30, marginBottom: 35, fontSize: 23, fontWeight: 900, color: '#212529',}}>€ <AnimatedNumber duration={500} value={this.state.totaalStats.refunds} formatValue={this.formatCurrency}/></h2>	
					<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
						<h2 className="font-light text-muted" style={{fontSize: 12, marginBottom: 0, color: 'rgba(0,0,0,0.4)',}}>Excl. Servicekosten</h2>	
					</div>								
				</div>
				:
				<div className="campaign ct-charts graph-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
			</Col>
		</Row>
		);
	}	
	
	state = {totaalStats: {totaal: 0, tokens: 0, lockers: 0}}
	
	componentDidUpdate(prevProps) {
		if(this.props.stats !== prevProps.stats && this.props.stats !== 'loading'){			
			var totaalStats = {totaal: 0, tokens: 0, lockers: 0, refunds: 0};
			Object.values(this.props.stats).forEach(statsDate => {
				if(statsDate.tokenverkoop){
					totaalStats.tokens = totaalStats.tokens + (statsDate.tokenverkoop.omzet ? statsDate.tokenverkoop.omzet : 0);
				}
				if(statsDate.tokenretour){
					totaalStats.refunds = totaalStats.refunds - (statsDate.tokenretour.retourkosten ? statsDate.tokenretour.retourkosten : 0);
				}
				if(statsDate.oplaadpunten){
					Object.keys(statsDate.oplaadpunten).forEach(oplaadpunt => {totaalStats.tokens = totaalStats.tokens + statsDate.oplaadpunten[oplaadpunt].omzet});		
				}
				if(statsDate.kluisverkoop && statsDate.kluisverkoop.aantal){totaalStats.lockers = totaalStats.lockers + statsDate.kluisverkoop.omzet}				
			});	
			totaalStats.totaal = totaalStats.tokens + totaalStats.lockers + totaalStats.refunds;
			this.setState({totaalStats: totaalStats});
		}
	}
	
	formatCurrency = (value) => {return value.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
}

export default TotaalOmzet;



