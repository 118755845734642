import React from "react";
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import logo from "../../assets/images/pplogo.jpg";

function AfhaalPdf(props) {
	return (
		<Document>			
			<Page style={{padding: '15px 50px 15px 50px', fontWeight: 700}}>
				<View style={{width: '100%', height: 50}} fixed></View>
				<View style={{paddingBottom: 50}}>
					<View style={{width: '100%', justifyContent: 'center'}}>
						<Image src={logo} style={{width: 111, height: 85}} alt="logo" />
					</View>	
					<View style={{flexDirection: 'row', width: '100%', marginTop: 40, justifyContent: 'space-between', alignItems: 'flex-end', fontSize: 12}}>			
						<View>
							<View style={{paddingVertical: 2}}><Text style={{fontSize: 15, color: '#212121'}}>Uitdraai Verkoop</Text></View>
							<View style={{marginTop: 8}}><Text style={{color: '#444444'}}>{props.eventname}</Text></View>	
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportDate}</Text></View>
						</View>	
					</View>		
				</View>				
				<View style={{flexDirection: 'row', color: '#555555', fontSize: 10, paddingVertical: 12, justifyContent: 'space-between', borderBottomWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Item</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>Aantal</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>Transacties</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>BTW</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>Omzet incl.</Text>
					</View>
				</View>
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Tokens via online transacties</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.online.aantal}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.online.transacties}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>-</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ {props.data.online.omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>				
				{Object.keys(props.data.oplaadStats).map((oplaadpunt, index) => (				
					<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
						<View style={{width: '30%'}}>
							<Text>Tokens via {oplaadpunt}</Text>
						</View>
						<View style={{width: '17.5%', textAlign: 'right'}}>
							<Text>{props.data.oplaadStats[oplaadpunt].tokens}</Text>
						</View>
						<View style={{width: '17.5%', textAlign: 'right'}}>
							<Text>{props.data.oplaadStats[oplaadpunt].transacties}</Text>
						</View>
						<View style={{width: '17.5%', textAlign: 'right'}}>
							<Text>-</Text>
						</View>
						<View style={{width: '17.5%', textAlign: 'right'}}>
							<Text>€ {props.data.oplaadStats[oplaadpunt].omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
						</View>
					</View>
				))}
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Tokens geretourneerd</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.retourStats.aantal}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.retourStats.transacties}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>-</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ -{props.data.retourStats.retourkosten.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>	
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Kluisjes</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.kluisStats ? props.data.kluisStats.aantal : 0}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.kluisStats ? props.data.kluisStats.transacties : 0}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ {props.data.kluisStats ? props.data.kluisStats.btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ {props.data.kluisStats ? props.data.kluisStats.omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}</Text>
					</View>
				</View>		
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Transactiekosten</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>-</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.serviceKosten.transacties}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ -{(props.data.serviceKosten.partypay / (1.21) * 0.21).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ -{props.data.serviceKosten.partypay.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Transactiekosten doorbelast</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>-</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.serviceKosten.transacties}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ {(props.data.serviceKosten.partypay / (1.21) * 0.21).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ {props.data.serviceKosten.partypayPaid.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>				
				<View style={{flexDirection: 'row', fontSize: 12, fontWeight: 'bold', paddingVertical: 12, justifyContent: 'space-between', borderTopWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Totaal</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>-</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>{props.data.serviceKosten.transacties}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ {props.data.totaalStats.btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
					<View style={{width: '17.5%', textAlign: 'right'}}>
						<Text>€ {props.data.totaalStats.omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{width: '100%', height: 25}} fixed></View>
			</Page>			
		</Document>
	);
}

export default AfhaalPdf;