import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row} from 'reactstrap';	
import { functionsEU } from '../components/firebase/firebase';
import Datetime from 'react-datetime';
import TotaalOmzet from '../components/stats/totaalomzet';
import VerkoopOmzet from '../components/stats/verkoopomzet';
import OmzetPerUur from '../components/stats/omzetperuur';
import GemStats from '../components/stats/gemstats';
import AfhaalOmzet from '../components/stats/afhaalomzet';

const searchParams = new URL(window.location.href).searchParams;

class Stats extends React.Component {	
	render() {
		return (
		<div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 30, paddingBottom: 100}}>
			<Row>
				<Col lg={12}>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Statistieken</h2>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 16, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>{this.state.ename ? this.state.ename  : ''}</h2>
					</div>
				</Col>
			</Row>
			<Row style={{marginTop: 40, paddingLeft: 15, paddingRight: 15}}>
				<Col lg={2} style={{minWidth: 240, padding: 0}}>
					<div className="graph-display dateselectwin">
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Vandaag' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Vandaag' ? 6 : 0}} onClick={() => this.getStats('Vandaag')}>
							<span>Vandaag</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Gisteren' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Gisteren' ? 6 : 0}} onClick={() => this.getStats('Gisteren')}>
							<span>Gisteren</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Deze week' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Deze week' ? 6 : 0}} onClick={() => this.getStats('Deze week')}>
							<span>Deze week</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Deze maand' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Deze maand' ? 6 : 0}} onClick={() => this.getStats('Deze maand')}>
							<span>Deze maand</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Dit jaar' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Dit jaar' ? 6 : 0}} onClick={() => this.getStats('Dit jaar')}>
							<span>Dit jaar</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Vorig jaar' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Vorig jaar' ? 6 : 0}} onClick={() => this.getStats('Vorig jaar')}>
							<span>Vorig jaar</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Periode' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Periode' ? 6 : 0}} onClick={() => this.togglePeriodModal()}>
							<span>Periode</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Alles' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Alles' ? 6 : 0}} onClick={() => this.getStats('Alles')}>
							<span>Alles</span>
						</div>
					</div>
					<GemStats stats={this.state.stats} />
				</Col>
				<Col className="rightCol">		
					<Row style={{paddingTop: 5}}>
						<Col lg={12}>
							<div style={{marginRight: 15, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className="font-light text-muted topTitle" style={{fontSize: 24, marginBottom: 0, fontWeight: 900, color: '#212529'}}>{this.state.statsNav}</h2>
									{this.state.statsNav !== 'Vandaag' && this.state.statsNav !== 'Gisteren' ?
									<h2 className="font-light text-muted topTitle" style={{marginLeft: 10, fontSize: 15, marginBottom: 0, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>{this.state.dateTitle}</h2>
									:null}
								</div>
								<div style={{display: 'flex'}}>
									<div onClick={() => this.getStats(this.state.statsNav)} className="refreshBtn">
										<i class="fa-regular fa-sync refreshBtnI" style={{color: '#525f7f'}}></i>
									</div>
								</div>
							</div>
						</Col>
					</Row>		
					<TotaalOmzet stats={this.state.stats} />
					<VerkoopOmzet stats={this.state.stats} exportDate={this.state.dateTitle} eventname={this.state.ename ? this.state.ename  : ''}/>
					<OmzetPerUur stats={this.state.stats} />
					<AfhaalOmzet stats={this.state.stats} exportDate={this.state.dateTitle} eventname={this.state.ename ? this.state.ename  : ''}/>									
				</Col>
			</Row>
			<Modal isOpen={this.state.periodModal} toggle={() => this.togglePeriodModal()} className={this.props.className} centered >
				<ModalHeader toggle={this.togglePeriodModal}>Selecteer een periode</ModalHeader>
				<ModalBody>
					<Row>
						<Col lg={6}>
							<span style={{color: 'rgba(0,0,0,0.5)', marginTop: 20}}>van</span>
							<div className="period" style={{marginTop: 5, marginBottom: 10, width: '100%', display: 'flex', position: 'relative', alignItems: 'center'}}>
								<i class="fa fa-calendar-day" style={{position: 'absolute', right: 25, top: 13, color: '#525f7f'}}></i>
								<Datetime timeFormat={false} value={this.state.periodStart} onChange={e => this.setState({periodStart: e})} closeOnSelect={true} dateFormat="DD-MM-YYYY" locale="nl"/>
							</div>
						</Col>
						<Col lg={6}>
							<span style={{color: 'rgba(0,0,0,0.5)', marginTop: 20}}>t/m</span>
							<div className="period" style={{marginBottom: 15, marginTop: 5, width: '100%', display: 'flex', position: 'relative', alignItems: 'center'}}>												
								<i class="fa fa-calendar-day" style={{position: 'absolute', right: 25, top: 13, color: '#525f7f'}}></i>
								<Datetime timeFormat={false} value={this.state.periodEnd} onChange={e => this.setState({periodEnd: e})} closeOnSelect={true} dateFormat="DD-MM-YYYY" locale="nl"/>
							</div>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.getStats('Periode')}>
					  Bekijk statistieken
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={() => this.togglePeriodModal()}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={this.state.errorModal} toggle={() => this.toggleErrorModal()} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleErrorModal}>Ongeldige link</ModalHeader>
				<ModalBody>
					<p>Er is iets mis gegaan. Deze link is ongeldig of geeft geen toegang (meer) tot de data. Neem contact op met de organisatie voor een nieuwe link.</p>
				</ModalBody>
				<ModalFooter>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={() => this.toggleErrorModal()}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
		</div>
		);
	}
	
	state = {
		statsNav: '',
		dateTitle: '',
		loaded: false,
		stats: 'loading',		
		periodModal: false,
		periodStart: new Date(),
		periodEnd: new Date(),
		lastDay: new Date(),
		errorModal: false,
	}
	
	componentDidMount = () => {this.getStats('Vandaag')}
	
	getStats(period){
		if(searchParams.has("code")){
			var date = new Date();		
			var startDate = new Date();
			var endDate = new Date();
			var dateTitle = '';
			if(period === 'Vandaag'){
				startDate.setDate(date.getDate());
				endDate.setDate(date.getDate());
				dateTitle = ("0" + startDate.getDate()).slice(-2) + '-' + ("0" + (startDate.getMonth() +1)).slice(-2) + '-' + startDate.getFullYear();
			}		
			if(period === 'Gisteren'){
				startDate.setDate(date.getDate() - 1);
				endDate.setDate(date.getDate() - 1);
				dateTitle = ("0" + startDate.getDate()).slice(-2) + '-' + ("0" + (startDate.getMonth() +1)).slice(-2) + '-' + startDate.getFullYear();
			}		
			if(period === 'Deze week'){
				startDate.setDate((date.getDate() - date.getDay())+1);
				endDate.setDate((date.getDate() - date.getDay())+7);
			}		
			if(period === 'Deze maand'){
				startDate = new Date(date.getFullYear(), date.getMonth(), 1);
				endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
			}		
			if(period === 'Dit jaar'){
				startDate = new Date(date.getFullYear(), 0, 1);
				endDate = new Date(date.getFullYear(), 11, 31);
			}		
			if(period === 'Vorig jaar'){
				startDate = new Date(date.getFullYear() -1, 0, 1);
				endDate = new Date(date.getFullYear() -1, 11, 31);
			}		
			if(period === 'Periode'){
				startDate = new Date(this.state.periodStart);
				endDate = new Date(this.state.periodEnd);
			}	
			if(period === 'Alles'){
				startDate = new Date(null);
				endDate = new Date(2170, 0, 1);
				dateTitle = "Alle data";
			}
			if(period === 'Deze week' || period === 'Deze maand' || period === 'Periode'){
				var startDateString = ("0" + startDate.getDate()).slice(-2) + '-' + ("0" + (startDate.getMonth() +1)).slice(-2) + '-' + startDate.getFullYear();
				var endDateString = ("0" + endDate.getDate()).slice(-2) + '-' + ("0" + (endDate.getMonth() +1)).slice(-2) + '-' + endDate.getFullYear();
				if(startDateString === endDateString){
					dateTitle = startDateString;
				}
				else{
					dateTitle = startDateString + ' t/m ' + endDateString;
				}			
			}		
			if(period === 'Dit jaar' || period === 'Vorig jaar'){
				dateTitle = startDate.getFullYear();
			}					
			this.setState({stats: 'loading', statsNav: period, dateTitle: dateTitle, periodModal: false, lastDay: endDate});
			const formatedTime = startDate.getFullYear() + '-' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '-' + ("0" + startDate.getDate()).slice(-2);
			const formatedTimeEnd = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);	
			var getStats = functionsEU.httpsCallable('getStats');
			getStats({oid: searchParams.get("oid"), eid: searchParams.get("eid"), code: searchParams.get("code"), start: formatedTime, end: formatedTimeEnd}).then(result => {
				if(result.data.state === 1){this.setState({loaded: true, stats: result.data.data ? result.data.data : [], ename: result.data.ename})}
				else{this.setState({errorModal: true})}
			}).catch(err => this.setState({errorModal: true}));
			}
		else{this.setState({errorModal: true})}
	}
	
	togglePeriodModal = () => this.setState({'periodModal': !this.state.periodModal});	
	toggleErrorModal = () => this.setState({'errorModal': !this.state.errorModal});	
	formatValue = (value) => {return value.toFixed(0)}	
	formatCurrency = (value) => {return value.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
	
    getPeriod = () =>{
		var date = new Date();
		if(this.state.statsNav === 'Vandaag'){	
			return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2) + '-' + date.getFullYear();
		}		
		if(this.state.statsNav === 'Gisteren'){
			date.setDate(date.getDate() - 1)
			return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2) + '-' + date.getFullYear();
		}	
		return this.state.dateTitle;		
	}
	
	
	
}

export default Stats;



